.App {
  background: rgba(28, 30, 60, 1);
  height: 100vh;
  padding: 20px;
}

input[type="tel"] {
  font-family: "Open Sans", sans-serif !important;
  font-weight: bold;
  background: rgba(53, 67, 98, 1);
  color: #fff !important;
}
